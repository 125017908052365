import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';

import Homepage from './routes/homepage'
import Menu from './routes/menu'
import Drinks from './routes/drinks'
import Specials from './routes/specials'
import Catering from './routes/catering'
import DineinRating from './routes/dineinrating'
import ScheduleLogin from './routes/schedule_login'



const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/menu",
    element: <Menu activeSubMenu="starters"/>,
  },
  {
    path: "/drinks-happy-hour",
    element: <Drinks activeSubMenu="beer"/>,
  },
  {
    path: "/specials",
    element: <Specials/>,
  },
  {
    path: "/catering-menu",
    element: <Catering/>,
  },
  {
    path: "/dine-in-rating",
    element: <DineinRating />,
  },
  {
    path: "/schedule-login",
    element: <ScheduleLogin />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();